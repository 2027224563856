import { windowGlobal } from './localStorage'
import { v4 as uuidv4 } from 'uuid'

export type SessionStorageTokenPathKeys = 'sessionId'

export function getSessionId() {
    let sessionId = getItem('sessionId')
    if (sessionId == null) {
        sessionId = uuidv4()
        setItem('sessionId', sessionId)
    }
    return sessionId
}

export const getItem = (path: SessionStorageTokenPathKeys) =>
    windowGlobal?.sessionStorage?.getItem(path)

export const setItem = (path: SessionStorageTokenPathKeys, item: string) =>
    windowGlobal?.sessionStorage?.setItem(path, item)

export const removeItem = (path: SessionStorageTokenPathKeys) =>
    windowGlobal?.sessionStorage?.removeItem(path)
